
import $ from 'jquery';

var thelang = document.getElementsByTagName('html')[0].getAttribute('lang');

class mscsSummerSchools{
    constructor(){      
        this.divcontainer = $(".summer-schools-list"); 
        var thelang=$(".summer-schools-list").data("language");
        if (thelang) {
            this.language = thelang;
        } else {       
            this.language = document.getElementsByTagName('html')[0].getAttribute('lang');
        }
        var foreas=$(".summer-schools-list").data("organizer");
        if (foreas) {
            this.foreas = "&foreas="+foreas;
        } else {       
            this.foreas = "";
        }
        var thetitle=$(".summer-schools-list").data("title");
        if (thetitle) {
            this.title = "<h2 class='search-ovelay__section-title'>"+thetitle+"</h2>";
        } else {       
            this.title = "";
        }
        
        
        this.events();
    }   
  
    // Events
    events() { 
        
        this.getResults();
        

    }
    // Methods    
    getResults() {
        //this.searchResults.html("ok");        
        var jsonlink;
        if (this.language == "el") {
            jsonlink='https://summer-schools.uoc.gr/wp-json/summerschools/v1/list/?language=el'+this.foreas;
            $.getJSON(jsonlink, mscssites => {            
                this.divcontainer.html(`
                    ${this.title}
                    ${mscssites.length ? `<div class="mscs-results-list min-list">` : 'Δεν βρέθηκαν αποτελέσματα' }                                
                    ${mscssites.map(msc => `                                   
                        <div class="mscs-results-list__msc">
                            <div class='mscs-results-list__img-container'><img class='mscs-results-list__img' src='${msc.mscThumbUrl}' /></div>
                            <div class='mscs-results-list__title'><a href="${msc.url}">${msc.title}</a></div>
                            <div class='mscs-results-list__foreis'>
                                ${msc.organizingBodies.map(bd => `<a target='_blank' href='${bd.organizerWebsite}'>${bd.foreasThumbUrl ? `<img src='${bd.foreasThumbUrl}' />` : ''} ${bd.organizerName}</a>`).join(', ')}
                            </div>
                        </div>
                    `).join('')}                                    
                    ${mscssites.length ? '</div>' : '' }
                `);
            });           
        } else {
            jsonlink='https://summer-schools.uoc.gr/wp-json/summerschools/v1/list/?language=en';
            $.getJSON(jsonlink, summerschools => {            
                this.divcontainer.html(`
                    ${this.title}
                    ${summerschools.length ? `<div class="summer-schools min-list">` : 'No results' }                                
                    ${summerschools.map(ss => `                                   
                        <div class="summer-school">                            
                            <div class='summer-school__title' target='_blank'><h4><a href="${ss.properties.url}">${ss.properties.title}</a></h4></div>
                            <div class='summer-school__meta'>
                                <div class="location"><span>Location</span>${ss.properties.location}</div>
                                <div class="dates"><span>Dates</span>${ss.properties.date}</div>
                                <div class="website"><span>Webite</span><a href="${ss.properties.website}" target="_blank">${ss.properties.website}</a></div>
                                
                            </div>                            
                        </div>
                    `).join('')}                                                   
                    ${summerschools.length ? '</div>' : '' }
                `);
            });
        }        
    }        
}

export default mscsSummerSchools;