
import $ from 'jquery';

var thelang = document.getElementsByTagName('html')[0].getAttribute('lang');
class mscsCalls{
    constructor(){
       // this.addSearchHTML();
        this.divcontainer = $(".mscs-calls"); 
        //this.openButton = $(".js-search-trigger"); 
        //this.closeButton = $(".search-overlay__close"); 
        //this.searchOverlay = $(".search-overlay");
        //this.searchField = $("#search-term");
        //this.searchResults=$(".search-overlay__results");
        //this.isOverlayOpen = false;
        //this.typingTimer;
        //this.isSpinnerVisible = false;
        //this.previousValue;
        this.language = document.getElementsByTagName('html')[0].getAttribute('lang');
        this.events();
    }    
  
    // Events
    events() {
        //this.openButton.on("click", this.openOverlay.bind(this));
        //this.closeButton.on("click", this.closeOverlay.bind(this));
        //$(document).on("keydown", this.keyPressed.bind(this));
        //this.searchField.on("keyup", this.typingLogic.bind(this));
        this.getResults();
    }

    // Methods
    openOverlay() {
        this.searchOverlay.addClass("search-overlay--active");
        $("body").addClass("body-no-scroll");
        setTimeout(() => this.searchField.focus(), 500);
        this.isOverlayOpen = true;
    }

    closeOverlay() {
        this.searchOverlay.removeClass("search-overlay--active");
        $("body").removeClass("body-no-scroll");
        this.isOverlayOpen = false;
    }
    keyPressed(key) {
        if (key.keyCode==27 && this.isOverlayOpen) {
            this.closeOverlay();
        }
    }
    typingLogic() {
        if (this.searchField.val()!== this.previousValue) {
            clearTimeout(this.typingTimer);
            if (this.searchField.val()) {
                if (!this.isSpinnerVisible) {
                    this.searchResults.html('<div class="spinner-loader"></div>');
                    this.isSpinnerVisible==true;
                }                
                this.typingTimer = setTimeout(this.getResults.bind(this), 500);    
            } else {                
                this.isSpinnerVisible == false;    
            }            
        }
        this.previousValue = this.searchField.val();
    }
    getResults() {
        //this.searchResults.html("ok");        
        if (this.language == "el") {
            $.getJSON('https://mscs.uoc.gr/wp-json/mscs/v1/calls?language=el', mscscalls => {            
                this.divcontainer.html(`<h2 class="search-ovelay__section-title">Ενεργές Προσκλήσεις</h2>                
                    ${mscscalls.length ? `<div class="mscs-results-list min-list">` : '' }                                
                    ${mscscalls.map(msc => `<div class="mscs-calls-list__msc">
                    <h3 class="website-title">Από την ιστοσελίδα: <a href="${msc[0].url}">${msc[0].title}</a></h3>
                    ${msc[0].posts.length ? `<div class="msc-posts-list min-list">` : '' }                                
                    ${msc[0].posts.map(mscpost => `
                        <div class="msc-call">
                            <h4><a href="${mscpost.post.url}">${mscpost.post.title}</a></h4>
                            <div class="deadline">Προθεσμία: <span>${mscpost.post.deadline_el}</span></div>
                            <div class="modification-date">Τελευταία Ημερομηνία Ενημέρωσης: <span>${mscpost.post.postModificationDate_el}</span></div>
                            <div class="short-description">Περίληψη: <span>${mscpost.post.short_description}</span></div>
                        </div>
                    `).join('')}                                                        
                    ${msc[0].posts.length ? '</div>' : '' }
                    </div>
                `).join('')}                                    
                    ${mscscalls.length ? '</div>' : '' }
                `);
            });           
        } else {
            $.getJSON('https://mscs.uoc.gr/wp-json/mscs/v1/calls?language=en', mscscalls => {            
                this.divcontainer.html(`
                    <div class='title-container'>
                        <h2 class="mscs-calls__title">Active Calls for Master's Programs at the University of Crete</h2>                
                    </div>
                    ${mscscalls.length ? `<div class="mscs-calls-list  min-list">` : 'No Active Calls for Master\'s at the University of Crete' }                                
                    ${mscscalls.map(msc => `<div class="mscs-calls-list__msc">
                        <h3 class="website-title">From website: <a href="${msc[0].url}">${msc[0].title}</a></h3>
                        ${msc[0].posts.length ? `<div class="msc-posts-list min-list">` : 'No Active Calls for Master\'s at the University of Crete' }                                
                        ${msc[0].posts.map(mscpost => `
                            <div class="msc-call">
                                <h4><a href="${mscpost.post.url}">${mscpost.post.title}</a></h4>
                                <div class="deadline">Deadline: <span>${mscpost.post.deadline}</span></div>
                                <div class="modification-date">Last update date: <span>${mscpost.post.postModificationDate}</span></div>
                                <div class="short-description">Excerpt: <span>${mscpost.post.short_description}</span></div>
                            </div>
                        `).join('')}                                                        
                        ${msc[0].posts.length ? '</div>' : '' }
                        </div>
                    `).join('')}                                                        
                    ${mscscalls.length ? '</div>' : '' }
                `);
            });
        }        
    }        
}

export default mscsCalls;