
import $ from 'jquery';

var thelang = document.getElementsByTagName('html')[0].getAttribute('lang');

class mscsPrograms{
    constructor(){      
        this.divcontainer = $(".mscs-programs"); 
        var thelang=$(".mscs-programs").data("language");
        if (thelang) {
            this.language = thelang;
        } else {       
            this.language = document.getElementsByTagName('html')[0].getAttribute('lang');
        }
        var foreas=$(".mscs-programs").data("organizer");
        if (foreas) {
            this.foreas = "&foreas="+foreas;
        } else {       
            this.foreas = "";
        }
        var thetitle=$(".mscs-programs").data("title");
        if (thetitle) {
            this.title = "<h2 class='search-ovelay__section-title'>"+thetitle+"</h2>";
        } else {       
            this.title = "";
        }
        var calldelay=$(".mscs-programs").data("callorder");
        if (calldelay) {
            this.delay = calldelay*1000;
        } else {       
            this.delay = 0;
        }
        
        this.events();
    }   
  
    // Events
    events() { 
        if (this.delay>0) {
            setTimeout ( this.getResults(), this.delay );
        } else {
            this.getResults();
        }

    }
    // Methods    
    getResults() {
        //this.searchResults.html("ok");        
        var jsonlink;
        if (this.language == "el") {
            jsonlink='https://mscs.uoc.gr/wp-json/uoc/v1/mscs?language=el'+this.foreas;
            $.getJSON(jsonlink, mscssites => {            
                this.divcontainer.html(`
                    ${this.title}
                    ${mscssites.length ? `<div class="mscs-results-list min-list">` : 'Δεν βρέθηκαν αποτελέσματα' }                                
                    ${mscssites.map(msc => `                                   
                        <div class="mscs-results-list__msc">
                            <div class='mscs-results-list__img-container'><img class='mscs-results-list__img' src='${msc.mscThumbUrl}' /></div>
                            <div class='mscs-results-list__title'><a href="${msc.url}">${msc.title}</a></div>
                            <div class='mscs-results-list__foreis'>
                                ${msc.organizingBodies.map(bd => `<a target='_blank' href='${bd.organizerWebsite}'>${bd.foreasThumbUrl ? `<img src='${bd.foreasThumbUrl}' />` : ''} ${bd.organizerName}</a>`).join(', ')}
                            </div>
                        </div>
                    `).join('')}                                    
                    ${mscssites.length ? '</div>' : '' }
                `);
            });           
        } else {
            jsonlink='https://mscs.uoc.gr/wp-json/uoc/v1/mscs?language=en'+this.foreas;
            $.getJSON(jsonlink, mscssites => {            
                this.divcontainer.html(`
                    ${this.title}
                    ${mscssites.length ? `<div class="mscs-results-list  min-list">` : 'No results' }                                
                    ${mscssites.map(msc => `                                   
                        <div class="mscs-results-list__msc">
                            <div class='mscs-results-list__img-container'><img class='mscs-results-list__img' src='${msc.mscThumbUrl}' /></div>
                            <div class='mscs-results-list__title'><a href="${msc.url}">${msc.title}</a></div>
                            <div class='mscs-results-list__foreis'>
                                ${msc.organizingBodies.map(bd => `<a target='_blank' href='${bd.organizerWebsite}'>${bd.foreasThumbUrl ? `<img src='${bd.foreasThumbUrl}' />` : ''} ${bd.organizerName}</a>`).join(', ')}
                            </div>
                        </div>
                    `).join('')}                                                   
                    ${mscssites.length ? '</div>' : '' }
                `);
            });
        }        
    }        
}

export default mscsPrograms;